import React from 'react';
import { Typography, TextField, Tooltip, MenuItem, Stack } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import RowLabel from './RowLabel';
import ColumnLabel from './ColumnLabel';
import { CssTextField } from './CssTextField';
import { useSelector } from 'react-redux';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useEffect } from 'react';

const BaseComponent = (props) => {
    const { formState, getValues, setValue } = useFormContext();
    const { fieldsVariant } = useSelector((state) => state.customization);
    const {
        name,
        field,
        options,
        isReadOnly,
        defaultValue,
        label,
        valueName,
        labelName,
        disabled,
        menuAnchorRef,
        rowDirection = false,
        optionAll = false,
        inputProps,
        tooltip,
        changeFn = () => {},
        variant = fieldsVariant ?? 'standard',
        ...other
    } = props;
    const { onChange, value } = field;
    const { errors } = formState;

    useEffect(() => {
        if (defaultValue) {
            // console.log('defaultValue', name, defaultValue);
            setValue(name, defaultValue);
        }
    }, [defaultValue, name, setValue]);

    return (
        <Stack direction={rowDirection ? 'row' : 'column'}>
            {variant === 'standard' && (
                <>
                    {rowDirection ? ( // variant = 'standard'
                        <RowLabel label={label} />
                    ) : (
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <ColumnLabel label={label} />
                            {tooltip && (
                                <Tooltip title={tooltip}>
                                    <HelpOutlineIcon fontSize="small" />
                                </Tooltip>
                            )}
                        </Stack>
                    )}
                </>
            )}
            <CssTextField
                error={!!errors[name]}
                size="small"
                helperText={errors[name]?.message}
                select
                InputProps={{
                    disableUnderline: true
                }}
                SelectProps={{
                    ...inputProps,
                    id: 'dropdown',
                    onChange: (e) => {
                        onChange(e);
                        changeFn();
                    },
                    value: value || '',
                    MenuProps: {
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center'
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        }
                    },
                    defaultValue,
                    displayEmpty: true
                }}
                fullWidth
                label={variant === 'standard' ? null : label}
                variant={variant}
                disabled={isReadOnly || disabled}
            >
                {optionAll && (
                    <MenuItem key="all" value="">
                        <em>Όλα</em>
                    </MenuItem>
                )}
                {Array.isArray(options) ? (
                    options.map((option) => (
                        <MenuItem key={valueName ? option[valueName] : option?.value} value={valueName ? option[valueName] : option?.value}>
                            {labelName ? option[labelName] : option?.label}
                        </MenuItem>
                    ))
                ) : (
                    <div>No options available...</div>
                )}
            </CssTextField>
        </Stack>
    );
};

const Dropdown = (props) => {
    const { control } = useFormContext();
    const { name, rules, changeFn, disabled, ...other } = props;

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={other.defaultValue}
            rules={rules}
            {...other}
            render={(props) => <BaseComponent name={name} disabled={disabled} changeFn={changeFn} {...props} {...other} />}
        />
    );
};

export default Dropdown;
